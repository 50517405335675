import { Link } from '@mui/material';
import { DetailsContainer, DetailsWrapper, SectionContainer } from 'components/base';
import calculateExperience from 'helpers/calculateExperience';
import formatDate from 'helpers/formatDate';
import useAuth from 'hooks/useAuth';
import { IClinicianContractDetails } from 'interfaces/ClinicianContract/IClinicianContractDetails';

interface Props {
  clinicianContract: IClinicianContractDetails
}

const ContractedParties = ({ clinicianContract }: Props) => {
  const clinician = clinicianContract.clinician;
  const { user } = useAuth();

  return(
    <SectionContainer title="Contracted Parties">
      <DetailsWrapper>
        <DetailsContainer name="clinician" label="Clinician" skipPermissions width="wide">
          <Link href={`/clinicians/${clinician.id}`} target="_blank">
            {`${clinician.last_name} ${clinician.first_name}${clinician.maiden_name ?
              ` (${clinician.maiden_name}),` :','} ${clinician.title}`}
          </Link>
        </DetailsContainer>
        <DetailsContainer name='grad_date' label='Grad Date' skipPermissions width="wide">
          {formatDate(clinician.grad_date as string || '')}
        </DetailsContainer>
        <DetailsContainer name='grad_date' label='Experience' skipPermissions width="wide">
          {clinician.grad_date ? calculateExperience(clinician.grad_date as string) : ''}
        </DetailsContainer>
        <DetailsContainer name="facility" label="Facility" skipPermissions width="wide">
          { user?.role === 'Facility Admin' ?
            <>{clinicianContract.facility.name}</>
            :
            <Link href={`/facilities/${clinicianContract.facility.id}`} target="_blank">
              {clinicianContract.facility.name}
            </Link>
          }
        </DetailsContainer>
      </DetailsWrapper>
    </SectionContainer>
  );
};

export default ContractedParties;
