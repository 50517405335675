import { useState, useEffect } from 'react';

import { TextField } from '@mui/material';
import { useField } from 'formik';
import { ReactComponent as CheckboxChecked } from 'assets/icons/checkbox-checked.svg';
import { ReactComponent as CheckboxUnchecked } from 'assets/icons/checkbox-unchecked.svg';

import { useFieldsPermissions } from 'context/FieldsPermissionsContext';

import { ISelectOption } from 'interfaces/ISelectOption';
import { WidthType } from 'types';

import { StyledFormControl, StyledAutocomplete, StyledListItem, StyledCheckbox, StartAdornment } from './styledComponents';

interface SearchableMultiselectProps {
  label: string;
  name: string;
  options: ISelectOption[];
  onChange?: Function;
  onBlur?: Function;
  required?: boolean;
  disabled?: boolean;
  initial?: Array<ISelectOption>;
  width?: WidthType;
  allOptionAvailable?: boolean;
  permissionKey?: string;
  skipPermissionsCheck?: boolean;
  styles?: {
    width?: string
  }
}

const allOption: ISelectOption = { id: 'all', name: 'All' };

const sortOptions = (options: Array<ISelectOption>, selectedValues: Array<ISelectOption>, allOptionAvailable: boolean) => {
  if (options.length === 0) {
    return [];
  }
  const selectedWithoutAll = selectedValues.filter((option) => option.id !== 'all');
  const notSelectedOptions = options.filter(
    (option) => !selectedWithoutAll.map((selected) => selected.id).includes(option.id)
  );
  const optionsList = ([] as ISelectOption[]).concat(selectedWithoutAll, notSelectedOptions);
  return (allOptionAvailable ? [allOption].concat(optionsList) : optionsList);
};

const SearchableMultiselect = ({
  label,
  name,
  options,
  onChange,
  onBlur,
  required = false,
  disabled = false,
  initial = [],
  width = 'normal',
  allOptionAvailable = false,
  permissionKey = name,
  skipPermissionsCheck = false,
  styles,
}: SearchableMultiselectProps) => {

  const [field, meta, helpers] = useField(name);
  const { fieldsPermissions } = useFieldsPermissions();

  const [placeholder, setPlaceholder] = useState({text: '', startAdornmentValue: ''});
  const [insideChange, setInsideChange] = useState(true);
  const [hasChanged, setHasChanged] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const updatePlaceholder = (value: Array<ISelectOption>) => {
    if (value.length) {
      if (value.length === 1) {
        setPlaceholder({ text: value[0].name, startAdornmentValue: '' });
      } else if (value.find(option => option.id === 'all')) {
        setPlaceholder({ text: 'All', startAdornmentValue: '' });
      } else {
        setPlaceholder({ text: value.map(item => item.name).join(', '), startAdornmentValue: `${value.length}:` });
      };
    } else {
      setPlaceholder({text: '', startAdornmentValue: ''});
    }
  };

  const handleChange = (selectedOptions: Array<ISelectOption>, reason: string, selectedOption: ISelectOption | undefined) => {
    setInsideChange(true);
    const selectedWithoutAll = selectedOptions.filter((option) => option.id !== 'all');
    if (reason === 'selectOption' && (selectedOption?.id === 'all' || selectedWithoutAll.length === options.length)) {
      const allOptions = [allOption].concat(options);
      helpers.setValue(allOptions);
      updatePlaceholder(allOptions);
    } else if (reason === 'removeOption' && selectedOption?.id === 'all') {
      helpers.setValue([]);
      updatePlaceholder([]);
    } else {
      helpers.setValue(selectedWithoutAll);
      updatePlaceholder(selectedWithoutAll);
    };
  };

  useEffect(() => {
    updatePlaceholder(field.value);
    if (!insideChange) {
      if (options.length > 0) {
        if (options.length === initial.length) {
          const allOptions = [allOption].concat(options);
          helpers.setValue(allOptions);
          updatePlaceholder(allOptions);
        }
      };
    }
    setInsideChange(false);
  // https://github.com/facebook/react/issues/14476#issuecomment-471199055
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(initial), JSON.stringify(field.value)]);

  const inputProps = (params: any, placeholder: any) => {
    const baseInputProps = {
      ...params.InputProps,
      'aria-label': label,
      endAdornment: (
        <>
          {params.InputProps.endAdornment}
        </>
      ),
    };
    return (placeholder.startAdornmentValue ? {
      ...baseInputProps,
      startAdornment: (
        <StartAdornment>
          {params?.inputProps?.['aria-expanded'] ? undefined : placeholder.startAdornmentValue}
        </StartAdornment>
      )} : baseInputProps) ;
  };

  return (
    <>
      {(skipPermissionsCheck || fieldsPermissions.read_allowed.includes(permissionKey)) &&
        <StyledFormControl styles={styles} size="small" error={meta.touched && !!meta.error} required={required} width={width}>
          <StyledAutocomplete
            multiple
            size="small"
            {...field}
            disabled={disabled || !(fieldsPermissions.update_allowed.includes(permissionKey) || skipPermissionsCheck)}
            options={sortOptions(options, field.value, allOptionAvailable)}
            inputValue={inputValue}
            loadingText="Loading…"
            noOptionsText="No results found"
            disableCloseOnSelect
            data-testid={`select-${name}`}
            isOptionEqualToValue={(option: any, anotherOption: any) => option.id === anotherOption.id}
            getOptionLabel={(option: any) => option.name}
            onClose={() => setInputValue('')}
            renderOption={(props, option: any, { selected }) =>
              <StyledListItem {...props} key={option.id}>
                <StyledCheckbox
                  checked={selected}
                  checkedIcon={<CheckboxChecked/>}
                  icon={<CheckboxUnchecked />}
                />
                {option.name}
              </StyledListItem>
            }
            renderInput={(params) => {
              return (
                <TextField
                  name={name}
                  {...params}
                  label={label}
                  ref={params.InputProps.ref}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder={params?.inputProps?.['aria-expanded'] ? undefined : placeholder.text}
                  inputProps={{ ...params.inputProps, 'aria-label': 'label' }}
                  InputProps={inputProps(params, placeholder)}
                />
              );
            }}
            onChange={(e, newValue: any, reason: string, selectedOption: any) => {
              handleChange(newValue, reason, selectedOption?.option);
              onChange?.(newValue, reason, selectedOption?.option);
              setHasChanged(true);
            }}
            onBlur={() => {
              if (hasChanged) {
                onBlur?.();
                setHasChanged(false);
              }
            }}
          />
        </StyledFormControl>
      }
    </>
  );
};


export default SearchableMultiselect;
