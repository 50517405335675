import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageContainer, ListOfRecords } from 'components/base';
import { ButtonMenu } from 'components/UIComponents';

import { CompensationGridAPI } from 'api/CompensationGridAPI';

import { ITableColumn } from 'interfaces/ITableColumn';
import { ICompensationGridTable } from 'interfaces/CompensationGrid/ICompensationGridTable';
import { engagementTypeOptions, clinicianTitleOptions, contractTypeOptions } from 'constants/compensationGridsConstants';
import useAuth from 'hooks/useAuth';

const ListOfCompensationGrids = () => {
  const { user } = useAuth();
  const editAllowed = user?.allowed_write_resources.includes('compensation_grids');
  const navigate = useNavigate();

  const columns: ITableColumn[] = [
    { dataField: 'facility_name', text: 'Facility', headStyle: { width: '40%' }},
    { dataField: 'clinician_title', text: 'Clinician Title', headStyle: { width: '20%' }},
    { dataField: 'contract_type', text: 'Contract Type', headStyle: { width: '20%' } },
    { dataField: 'engagement', text: 'Engagement', headStyle: { width: '20%' } },
  ];
  const [search, setSearch] = useState('');

  const dataFormatter = (item: ICompensationGridTable) => ({
    id: item.id,
    facility_name: item.facility_name,
    clinician_title: clinicianTitleOptions.find(option => option.id === item.clinician_title )?.name,
    contract_type: contractTypeOptions.find(option => option.id === item.contract_type )?.name,
    engagement: engagementTypeOptions.find(option => option.id === item.engagement )?.name,
  });

  return(
    <PageContainer type="wide" style={{marginBottom: '20px'}}>
      <ListOfRecords
        tableProps={{
          name: 'compensation_grids',
          columns: columns as ITableColumn[],
          editAction: editAllowed,
          withActions: editAllowed,
          rowsAsLinks: true,
        }}
        pageTitle='Compensation Grids'
        barActions={
          editAllowed &&
          <ButtonMenu
            buttonLabel='Add Compensation Grid'
            actions={[
              {
                title: 'Add Full-Time Compensation Grid',
                action: () => navigate('/compensation_grids/new?engagement=full_time&redirectTo=/compensation_grids'),
              },
              {
                title: 'Add Per-Diem Compensation Grid',
                action: () => navigate('/compensation_grids/new?engagement=per_diem&redirectTo=/compensation_grids'),
              },
            ]}
          />
        }
        dataFormatter={dataFormatter}
        dataProvider={CompensationGridAPI.getAll}
        searchProps={{
          allowSearch: true,
          search: search,
          setSearch: setSearch,
        }}
      />
    </PageContainer>
  );
};

export default ListOfCompensationGrids;
