import { contractEngagementTypeOptions, contractTypeOptions } from './contractsConstants';

export const facilityTypeOptions = [
  { id: 'Hospital', name: 'Hospital'},
  { id: 'ASC', name: 'ASC'},
  { id: 'OBSF', name: 'OBSF'},
];

export const stateOptions = [
  { id: 'AL', name: 'AL' },
  { id: 'AK', name: 'AK' },
  { id: 'AZ', name: 'AZ' },
  { id: 'AR', name: 'AR' },
  { id: 'CA', name: 'CA' },
  { id: 'CO', name: 'CO' },
  { id: 'CT', name: 'CT' },
  { id: 'DE', name: 'DE' },
  { id: 'DC', name: 'DC' },
  { id: 'FL', name: 'FL' },
  { id: 'GA', name: 'GA' },
  { id: 'HI', name: 'HI' },
  { id: 'ID', name: 'ID' },
  { id: 'IL', name: 'IL' },
  { id: 'IN', name: 'IN' },
  { id: 'IA', name: 'IA' },
  { id: 'KS', name: 'KS' },
  { id: 'KY', name: 'KY' },
  { id: 'LA', name: 'LA' },
  { id: 'ME', name: 'ME' },
  { id: 'MD', name: 'MD' },
  { id: 'MA', name: 'MA' },
  { id: 'MI', name: 'MI' },
  { id: 'MN', name: 'MN' },
  { id: 'MS', name: 'MS' },
  { id: 'MO', name: 'MO' },
  { id: 'MT', name: 'MT' },
  { id: 'NE', name: 'NE' },
  { id: 'NV', name: 'NV' },
  { id: 'NH', name: 'NH' },
  { id: 'NJ', name: 'NJ' },
  { id: 'NM', name: 'NM' },
  { id: 'NY', name: 'NY' },
  { id: 'NC', name: 'NC' },
  { id: 'ND', name: 'ND' },
  { id: 'OH', name: 'OH' },
  { id: 'OK', name: 'OK' },
  { id: 'OR', name: 'OR' },
  { id: 'PA', name: 'PA' },
  { id: 'RI', name: 'RI' },
  { id: 'SC', name: 'SC' },
  { id: 'SD', name: 'SD' },
  { id: 'TN', name: 'TN' },
  { id: 'TX', name: 'TX' },
  { id: 'UT', name: 'UT' },
  { id: 'VT', name: 'VT' },
  { id: 'VA', name: 'VA' },
  { id: 'WA', name: 'WA' },
  { id: 'WV', name: 'WV' },
  { id: 'WI', name: 'WI' },
  { id: 'WY', name: 'WY' },
];

export const clinicianTitleOptions = [
  { id: 'CRNA', name: 'CRNA' },
  { id: 'RN', name: 'RN' },
  { id: 'MD', name: 'MD' },
  { id: 'DO', name: 'DO' },
  { id: 'NP', name: 'NP' },
  { id: 'Admin', name: 'Admin' },
];

export const filteringOptions = [
  { id: 'payroll_company_names', name: 'Payroll Company' },
  { id: 'acronyms', name: 'PC Abbr' },
  { id: 'facility_names', name: 'Facility' },
  { id: 'statuses', name: 'Status' },
  { id: 'entity_names', name: 'Entity Name' },
  { id: 'contract_types', name: 'Contract Type' },
  { id: 'engagements', name: 'Engagement' },
  { id: 'execution_date', name: 'Execution Date' },
  { id: 'effective_date', name: 'Effective Date' },
];

export const dateConditionOptions = [
  { id: '=', name: 'is' },
  { id: '>', name: 'is after' },
  { id: '<', name: 'is before' },
];

export const comparingValueDropdownValues = {
  statuses: [
    { id: 'Active', name: 'Active' },
    { id: 'Inactive', name: 'Inactive' },
    { id: 'In Progress', name: 'In Progress' },
  ],
  contract_types: contractTypeOptions,
  engagements: contractEngagementTypeOptions,
};

export const amendmentTypes = [
  { id: 'Legal Amendment', name: 'Legal Amendment' },
  { id: 'One-Page Flyer', name: 'One-Page Flyer' },
];
