import { useState, useEffect, forwardRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useField } from 'formik';
import { InputAdornment } from '@mui/material';
import { useFieldsPermissions } from 'context/FieldsPermissionsContext';
import { StyledTextField } from './styledComponents';
import formatPhone from 'helpers/formatPhone';
import formatAccountRoutingNumber from 'helpers/formatAccountRoutingNumber';
import NumericFormatCustom from './NumericFormatCustom';
import IbanFormatInput from './IbanFormatInput';
import NumericFormatWithDecimal from './NumericFormatWithDecimal';
import { WidthType } from 'types';

interface TextFieldProps {
  label: string;
  name: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  onChange?: any;
  onBlur?: (trimmedValue: string) => any;
  helperText?: string;
  width?: WidthType;
  type?: 'text' | 'currency' | 'phone' | 'npi' | 'iban' | 'accountRoutingNumber' | 'twoDigits' |
    'part_time_fte' | 'full_time_fte' | 'threeDigits';
  skipPermissionsCheck?: boolean;
};

const inputProps = {
  'currency': { startAdornment: <InputAdornment position="start">$</InputAdornment>,
    inputComponent: forwardRef((props: any, ref: any) => <NumericFormatCustom maxValue={9999999999} {...props} />)},
  'phone': { startAdornment: <InputAdornment position="start">+1</InputAdornment>},
  'text': undefined,
  'npi': undefined,
  'iban': { inputComponent: IbanFormatInput as any },
  'accountRoutingNumber': undefined,
  'twoDigits': { inputComponent: forwardRef((props: any, ref: any) => <NumericFormatCustom maxValue={99} {...props} />)},
  'threeDigits': { inputComponent: forwardRef((props: any, ref: any) => <NumericFormatCustom maxValue={999} {...props} />)},
  'part_time_fte': { inputComponent: forwardRef((props: any, ref: any) => <NumericFormatWithDecimal maxValue={0.99} {...props} />)},
  'full_time_fte': { inputComponent: forwardRef((props: any, ref: any) => <NumericFormatWithDecimal maxValue={2} {...props} />)},
};

const phoneRegex = /^(([0-9]{3}-[0-9]{3}-[0-9]{3,4})|([0-9]{0,10}))$/;
const npiRegex = /^[0-9]{0,10}$/;
const accountRoutingNumberRegex = /^(([0-9]{3} [0-9]{3} [0-9]{2,3})|([0-9]{0,9}))$/;

const DEBOUNCE_DELAY = 200;

const TextField = ({
  label,
  name,
  required = false,
  disabled = false,
  onChange,
  onBlur,
  width = 'normal',
  type = 'text',
  skipPermissionsCheck = false,
}: TextFieldProps) => {
  const [field, meta, { setValue, setTouched }] = useField(name);
  const [visibleValue, setVisibleValue] = useState(field.value);
  const { fieldsPermissions } = useFieldsPermissions();

  const debouncedChange = useDebouncedCallback(
    (value) => {
      setValue(value);
    },
    DEBOUNCE_DELAY
  );

  useEffect(() => {
    setVisibleValue(field.value);
  }, [field.value]);

  return(
    <>
      {(skipPermissionsCheck || fieldsPermissions.read_allowed.includes(name)) &&
      <StyledTextField
        width={width}
        error={meta.touched && !!meta.error}
        {...field}
        value={visibleValue || ''}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChange?.(e);
          switch(type) {
          case 'phone':
            if (phoneRegex.test(e.target.value)) {
              setVisibleValue(formatPhone(e.target.value));
              debouncedChange(formatPhone(e.target.value));
            };
            break;
          case 'npi':
            if (npiRegex.test(e.target.value)) {
              setVisibleValue(e.target.value);
              debouncedChange(e.target.value);
            };
            break;
          case 'accountRoutingNumber':
            if (accountRoutingNumberRegex.test(e.target.value)) {
              setVisibleValue(formatAccountRoutingNumber(e.target.value));
              debouncedChange(formatAccountRoutingNumber(e.target.value));
            };
            break;
          default:
            setVisibleValue(e.target.value);
            debouncedChange(e.target.value);
          }
        }}
        InputProps={inputProps[type]}
        disabled={disabled || !(fieldsPermissions.update_allowed.includes(name) || skipPermissionsCheck)}
        required={required}
        variant="outlined"
        label={label}
        helperText={meta.touched && meta.error}
        size="small"
        onBlur={() => {
          setTouched(true); // To validate/show errors for field after trimming
          const trimmedValue = field.value?.trim();
          setVisibleValue(trimmedValue);
          setValue(trimmedValue);
          onBlur?.(trimmedValue);
        }}
      />
      }
    </>
  );
};

export default TextField;
